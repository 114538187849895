import request from '@/utils/http';
//goods===============================================================
export function sharesChange_api(data) {
  return request({
    url: '/v11/user/sharesChange',
    method: 'post',
    data
  })
}

// 物流单号
export function shipments(data) {
  return request({
    url: '/v5/user/shipments',
    method: 'get',
    params: data
  })
}

// 审核详情
export function sharesChangeDetails_api(id) {
  return request({
    url: '/v11/user/sharesChange/'+id,
    method: 'get',
  })
}


// 审核支付
export function sharesChangePay_api(data,id) {
  return request({
    url: '/v11/user/sharesChangePay/'+id,
    method: 'put',
    data
  })
}

// 审核支付
export function cloneSharesChange_api(id) {
  return request({
    url: '/v11/user/sharesChange/'+id,
    method: 'delete',
  })
}