<template>
  <div>
    <!-- 头部 -->
    <headerTab :headerConfig="headerConfig"></headerTab>
    <!-- 表單 -->
    <div class="i_content">
      <div class="i_from">
        <div class="address_top">
          <div class="a_item">
            <div class="address_t">公司地址：</div>
            <div>{{configData.shares_sales_receiver_address}}</div>
          </div>
          <div class="a_item">
            <div class="address_t">公司收件人：</div>
            <div>{{configData.shares_sales_receiver}}</div>
          </div>
          <div class="a_item">
            <div class="address_t">公司联系方式：</div>
            <div>{{configData.shares_sales_receiver_mobile}}</div>
          </div>
        </div>
        <div class="i_from_item">
          <div style="display:flex;position: relative;">
            <Input :config="alterationFrom.s_order_sn" />
            <div class="comfimBtn" @click="shipmentsBtn">确认</div>
          </div>
          <Input :config="alterationFrom.s_company" />
          <Input :config="alterationFrom.s_message" />
          <!-- 地址選擇 -->
          <div style="display:flex">
            <div class="address_t_1" style="margin-top:30px">我的地址</div>
            <div>
              <div class="address_p" v-if="address" @click="selectBtn">
                <div class="address_box1">
                  <div>联系人：{{address.receiver_name}}</div>
                  <div>联系电话：{{address.receiver_mobile}}</div>
                  <div>详细地址：{{address.receiver_address}}</div>
                </div>
              </div>

              <!-- 地址選擇 -->
              <div class="address_p" @click="selectBtn" v-else>
                <div class="address_box">选择地址 +</div>
              </div>
            </div>
          </div>

        </div>

        <div class="nextBtn_a" @click="nextBtnBtn">提交</div>
      </div>

    </div>

    <!-- 弹窗 -->

    <div class="dig_box" v-if="confimStatus">
      <div class="item_box">
        <img @click="confimStatus=false" src="../../assets/Slice367@2x.png" />
        <div class="dig_title">温馨提示</div>
        <div>请确认回寄信息填写正确，且与变更物料信息保持一致。</div>
        <div>单号：{{this.alterationFrom.s_order_sn.value}}</div>
        <div class="dig_confim" @click="digConfim">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
import headerTab from "@/components/headerTab";
import Input from "@/components/input";
import { getConfig_api } from "@/api/deal";
import { shipments, sharesChange_api } from "@/api/alteration";
import { Toast } from "vant";
export default {
  data() {
    return {
      // 表单类型
      isAlteration: true,
      //   地址选择
      address: false,
      //   数据提交确认
      confimStatus: false,
      // 放点击
      submitState: true,
      headerConfig: {
        title: "信息填写"
      },
      //   表单数据
      alterationFrom: {
        s_order_sn: {
          value: "",
          title: "寄送单号",
          inputStatus: 1,
          text: "请输入寄送单号",
          ladelWidth: true
        },
        s_company: {
          value: "",
          title: "物流公司",
          inputStatus: 1,
          text: "请输入物流公司",
          ladelWidth: true,
          disabled: true
        },
        s_message: {
          value: "",
          title: "备注信息",
          text: "请输入备注信息",
          ladelWidth: true,
          textarea: true
        }
      },
      shipmentsData: {},
      shipments: {},
      configData:{}
    };
  },
  components: {
    headerTab,
    Input
  },
  created() {
    document.title = "信息填写";
    this.getSeting();
    if (JSON.parse(localStorage.getItem("address"))) {
      this.address = JSON.parse(localStorage.getItem("address"));
    }
    if (JSON.parse(localStorage.getItem("shipments"))) {
      this.alterationFrom = JSON.parse(localStorage.getItem("shipments"));
      this.shipmentsBtn();
    }
  },
  methods: {
    getSeting() {
      let data = {
        config: ["shares_sales_receiver_address","shares_sales_receiver","shares_sales_receiver_mobile"]
      };
      getConfig_api(data).then(res => {
        this.configData = res.data;
      });
    },
    // 确认数据提交
    digConfim() {
      if (!this.submitState) return false;
      let informationData = JSON.parse(localStorage.getItem("audit_info"));
      informationData.forEach(e => {
        e.img = e.img[0].url;
      });
      let data = {
        audit_info: informationData,
        audit_remark: {
          remark: this.alterationFrom.s_message.value
        },
        audit_shipments: {
          receiver_name: this.address.receiver_name,
          receiver_mobile: this.address.receiver_mobile,
          receiver_address: this.address.receiver_address
        },
        shipments_info: {
          LogisticCode: this.alterationFrom.s_order_sn.value,
          Shipper: this.shipmentsData.Shippers?this.shipmentsData.Shippers[0].ShipperName:'',
          ShipperCode: this.shipmentsData.Shippers?this.shipmentsData.Shippers[0].ShipperCode:'',
          CustomerName: this.address.receiver_mobile.slice(7, 11)
        }
      };
      if(!this.verification(data)){
        return false
      }
      this.submitState = false;
      sharesChange_api(data).then(res => {
        this.submitState = true;
        if (res.code == 0) {
          localStorage.removeItem("shipments");
          localStorage.removeItem("audit_info");
          localStorage.removeItem("address");
          this.$router.push(`./nextAudit?id=${res.data.audit_id}`);
        }else{
           Toast({
                message: res.error,
                icon: "info",
                duration: 1000
              });
        }
      });
    },
    verification(data) {
      if (!data.audit_shipments.receiver_name) {
        Toast({
          message: "请选择地址",
          icon: "info",
          duration: 1000
        });
        return false;
      }
      for (var i in data.shipments_info) {
        if (!data.shipments_info[i]) {
          Toast({
            message: "请完善表单",
            icon: "info",
            duration: 1000
          });
          return false;
        }
      }
      return true
    },
    // 数据提交框
    nextBtnBtn() {
      this.confimStatus = true;
    },
    // 地址选择
    selectBtn() {
      localStorage.setItem("shipments", JSON.stringify(this.alterationFrom));
      this.$router.push("../../address");
    },
    shipmentsBtn() {
      let data = {
        LogisticCode: this.alterationFrom.s_order_sn.value
      };
      shipments(data).then(res => {
        if (res.code == 0) {
          this.shipmentsData = res.data;
          this.alterationFrom.s_company.value =
            res.data.Shippers[0].ShipperName;
          this.alterationFrom.s_company.disabled = false;
          console.log(this.alterationFrom.s_company.value);
        }
      });
    }
  }
};
</script>
<style>
.i_content {
  background-color: #f4f4f4;
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
}
.i_from {
  width: 700px;
  background-color: #fff;
  margin: 0 auto;
  padding: 55px 60px;
  box-sizing: border-box;
}
.addBtn {
  text-align: right;
  font-size: 28px;
  color: #0038ff;
  margin: 40px 0 60px;
}
.nextBtn_a {
  width: 300px;
  height: 80px;
  background: #f1f1f1;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #1a1a1a;
  margin: 50px auto 0;
}
.i_from_item {
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}

.i_from_item input {
  color: #1a1a1a;
}

.address_top {
  font-size: 30px;
  color: #1a1a1a;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}
.a_item {
  display: flex;
  margin-bottom: 30px;
}
.address_t {
  width: 220px;
  font-size: 30px;
  color: #1a1a1a;
}
.address_t_1 {
  width: 140px;
  font-size: 30px;
  color: #1a1a1a;
}
.comfimBtn {
  font-size: 28px;
  color: #0038ff;
  margin-left: 10px;
  position: absolute;
  right: -10px;
  top: 20px;
}
.address_p {
  margin-top: 30px;
}
.address_title {
  font-size: 30px;
  color: #fff;
  margin-left: 10px;
}
.address_box {
  width: 380px;
  height: 170px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid #eee;
  line-height: 170px;
  text-align: center;
  font-size: 30px;
  color: #0038ff;
  margin: 36px 0;
}
.address_box1 {
  width: 380px;
  height: 170px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid #eee;
  font-size: 24px;
  line-height: 40px;
  color: #0038ff;
  margin: 36px 0;
  padding: 12px 26px;
  box-sizing: border-box;
}
.dig_box {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
}
.item_box {
  width: 610px;
  height: 388px;
  background-image: url("../../assets/Slice366@2x.png");
  background-size: 100% 100%;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 400px auto 0;
  position: relative;
  padding: 40px 61px;
  opacity: 1;
  box-sizing: border-box;
}
.item_box img {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.item_box div {
  font-size: 30px;
  color: #1a1a1a;
  margin-bottom: 33px;
}
.item_box .dig_title {
  text-align: center;
  font-size: 38px;
  font-weight: 800;
  margin-top: 20px;
}
.item_box .dig_confim {
  font-size: 32px;
  color: #0038ff;
  text-align: center;
  margin-top: 40px;
}

.van-toast {
  width: 300px;
  min-height: 120px;
  line-height: 40px;
}
</style>


